import React from "react";
import { Container } from "react-bootstrap";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "components/Layout";
import "./Commitment.scss";

export default function Commitment({ location }) {
  const pathName = location.pathname.split("/")[1];

  return (
    <Layout pathName={pathName}>
      <Container fluid>
        <div className="toms-pool">
          <StaticImage
            src="../../content/assets/images/toms_pool.jpg"
            width="500"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Roseville Pool Service"
            placeholder="tracedSVG"
          />
        </div>
        <div className="intro">
          <h3>
            Roseville Pool Service <small>- Commitment to our Customers</small>
          </h3>
          <hr />
          <section>
            <p>
              We are committed to provide you with the best possible service for your swimming pool.
              In order to provide you with this superior service, we are careful that we do not take
              on more customers than we can handle. Superior service is simply a common sense
              philosophy we follow that can be very hard to find:
            </p>
            <ul>
              <li>
                <strong>We show up when we say we will.</strong> We look at each service visit as an
                appointment with our customer, even if you aren&apos;t at home when we come. If
                unforeseen circumstances do not permit us to service your pool on your scheduled
                day, we let you know and reschedule as early as possible.
              </li>
              <li>
                <strong>We provide the full service you are paying for.</strong> This commitment
                means we don&apos;t cut corners when you aren&apos;t watching or on things you may
                not be able to verify, such as whether or not we really test the water each week.
              </li>
              <li>
                <strong>We will treat your pool like it is our pool.</strong> This means taking a
                little extra time to do things that aren&apos;t specifically required of us, but
                which will keep your equipment running better and lasting longer.
              </li>
            </ul>
          </section>
        </div>
      </Container>
    </Layout>
  );
}

Commitment.propTypes = {
  location: PropTypes.object,
};

Commitment.defaultProps = {
  location: null,
};
